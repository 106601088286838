import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public afAuth: AngularFireAuth, public router: Router) {}

  isLoggedIn(): Observable<boolean> {
    return this.afAuth.authState
      .take(1)
      .map((user) => {
        return !!user;
      })
      .do((loggedIn) => {
        console.log(loggedIn);
        if (!loggedIn) {
          this.router.navigate(['/login']);
        }
      });
  }

  async signInWithEmail(email: string, password: string): Promise<void> {
    const result = await this.afAuth.signInWithEmailAndPassword(email, password).catch((error) => {
      return Promise.reject();
    });
    this.router.navigate(['/']);
    return Promise.resolve();
  }

  async logOut() {
    await this.afAuth.signOut();
    this.router.navigate(['auth']);
  }

}
