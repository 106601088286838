// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  siteUrl: 'https://dev.pipesnstuff.com',
  apiBaseUrl: 'https://api.dev.edtbank.com/api/edt',
  paymentHandler: 'https://dev.edtbank.com/payment-handler',
  firebase: {
    apiKey: "AIzaSyBTuxFd1A86TeNWC8SKL1FA525yrR5cor8",
    authDomain: "strategics-f44fd.firebaseapp.com",
    databaseURL: "https://strategics-f44fd.firebaseio.com",
    projectId: "strategics-f44fd",
    storageBucket: "strategics-f44fd.appspot.com",
    messagingSenderId: "832852700548",
    appId: "1:832852700548:web:8be97a8f9dc4e5bac7c1d1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
